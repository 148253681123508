const dayjs = useDayjs()
const checkDiffTime = (params: {
  base?: number
  another?: number
  value?: number
  unit?: any
}) => {
  const {
    base = new Date(),
    another = new Date(),
    value = 1,
    unit = 'd'
  } = params
  const baseDate = dayjs(base).startOf(unit)
  const anotherDate = dayjs(another).startOf(unit)
  const result = baseDate.diff(anotherDate, unit)

  return result >= value // 단순 날짜 차이 계산
}

function doDownloadClient() {
  const { $customDevice, $toast } = useNuxtApp()
  if ($customDevice.isMobileOrTablet) {
    $toast.warning('헬로메이플은 PC에서만 플레이가 가능합니다.', {
      toastId: 'download-mo'
    })
  } else {
    window.open(useRuntimeConfig().public.clientDownloadURL, '_blank')
    $toast.info(
      '헬로메이플 클라이언트 설치 파일이 다운로드되면 파일을 실행해 주세요.',
      { toastId: 'download-pc', autoClose: false }
    )
  }
}
export { checkDiffTime, doDownloadClient }
